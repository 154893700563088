/* LAYOUT */

* {
    box-sizing: border-box;
    transition: all 200ms;
}
.container {
    /* overflow-x: hidden; */
    width: 100%;
}
img {
    max-width: 100%;
}
.content {
    padding: 0 !important;
    padding-bottom: 40px !important;
    margin-top: 70px;
}
.content.noMargin {
    margin-top: 0;
}
.col.first {
    padding-left: 0 !important;
}
.col.last {
    padding-right: 0 !important;
}
.clear {
    clear: both;
    display: block;
    float: none;
    min-height: 1px;
    width: 100%;
}

/* VARIE */

div[role="status"] svg {
	margin: 0 auto;
}
tr.grey td {
    background: #eee;
}
td.tdcol span {
    border-bottom: 1px dotted #333;
    display: inline-block;
    padding-bottom: 5px;
    width: 100%;
}
#wait {
    background: rgba(0,0,0,.7);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#wait .percent {
	background: transparent;
	color: #fff;
	text-align: center;
	margin-bottom: 20px;
	font-size: 24px;
}

.btn-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: #014A94;
	--bs-btn-border-color: #014A94;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #0b5ed7;
	--bs-btn-hover-border-color: #0a58ca;
	--bs-btn-focus-shadow-rgb: 49,132,253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #014A94;
	--bs-btn-active-border-color: #014A94;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #014A94;
	--bs-btn-disabled-border-color: #014A94;
}
svg.last {
    float: right;
}
#query_form {
    display: flex;
}
#query_form svg {
    background: #111;
	color: #fff;
	height: 42px;
	padding: 11px;
	width: 42px;
}
#query_form input {
    margin: 0 !important;
}
table {
    border: 1px solid #ddd;
    margin: 0 auto 20px;
    width: calc(100% - 40px) !important;
}
table th, table td {
    border: 1px solid #ddd;
    padding: 2px 10px;
}
table th {
    background: #014A94 !important;
    color: #fff !important;
    font-weight: 600;
}
table.orderable th {
    cursor: pointer;
}
table.orderable th:hover {
    opacity: .8;
}
table td {
    line-height: 32px;
}
table td.action {
    text-align: center;
}
table td.action svg {
    cursor: pointer;
}
table td.action svg.delete {
    color: #930000;
}
table td.actions {
	max-width: 120px;
}
table td.actions button {
    border-radius: 0;
    display: inline-block;
    margin-right: 5px;
}
table td.actions button svg {
	font-size: 18px;
	margin-top: -5px;
}
table td.actions button.delete {
    background: #930000;
    border: 1px solid #930000;
}
.op_status {
    font-weight: 600;
}
.op_status span.square {
    content: "";
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
table td.title {
    background: #eee;
    font-weight: 600;
    padding: 5px 20px;
}
table td.title + td {
    padding: 5px 20px;
}

.change_status_block {
    background: #bddeef;
    margin: 15px 0 5px;
    padding: 10px 10px 15px;
}
.change_info_block {
    background: #b2db8a;
    margin: 15px 0 5px;
    padding: 10px 10px 15px;
}
fieldset input, fieldset select {
    background: #fff !important;
}
.fieldset_label {
    background: #fff;
    padding: 8px;
    display: inline-block;
    margin-left: -22px !important;
    margin-bottom: 0px !important;
    padding-right: 20px;
    padding-left: 20px;
    border-top-right-radius: 20px;
    font-weight: 600;
}
.fieldset_title {
    background: #014A94;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin: 0 20px 0 30px;
    padding: 2px 20px 5px 55px;
    position: relative;
}
.fieldset_title::before {
    background: #44B2EB;
    border-top-left-radius: 15px;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    z-index: 1;
    display: none !important;
}
.fieldset_title svg {
    color: #fff;
    position: absolute;
    font-size: 22px;
    top: 6px;
    left: 11px;
    z-index: 2;
    display: none !important;
}
#react-select-1-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox,
#react-select-5-listbox,
#react-select-6-listbox,
#react-select-7-listbox,
#react-select-8-listbox,
#react-select-9-listbox,
#react-select-10-listbox,
#react-select-11-listbox,
#react-select-12-listbox,
#react-select-13-listbox,
#react-select-14-listbox,
#react-select-15-listbox,
#react-select-16-listbox,
#react-select-17-listbox,
#react-select-18-listbox,
#react-select-19-listbox,
#react-select-20-listbox {
    z-index: 50 !important;
}
.space_top {
    margin-top: 20px;
}
.fieldset_title + .row {
    background: #eee;
    margin: 0 20px 0 30px;
    padding: 10px 10px 15px !important;
}
.fieldset_title + .row input {
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
    padding: 5px;
    width: 100%;
}

.acc_label {
    background: #999;
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    padding: 10px;
    text-align: center;
    font-weight: 600;
    margin: 0 10px;
    cursor: pointer;
    color: #fff;
}
.acc_label:hover, .acc_label.active {
    background: #f7f1cf;
    color: #111;
}
.acc_box {
    background: #f7f1cf;
    padding: 20px;
    margin: 0 -2px;
}
.acc_box input.styled {
    background: #fff !important;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 38px;
    margin: 0px;
}
.acc_box button, .acc_box button:hover {
    background: #157347;
}
.acc_box button:hover {
    opacity: .8;
}

.box_fieldset {
    background: #eee;
    margin: 0 35px;
    padding: 10px 0 10px;
}
.box_fieldset h2 {
    background: #333;
    border-top-right-radius: 20px;
    color: #fff;
    margin: 0 !important;
    max-width: 90%;
    padding: 10px 30px;
    width: 600px;
}
.box_fieldset .iiSEZI {
    margin: 0 20px 20px;
}
ul.filelist {
    list-style-type: none;
    padding: 0 20px;
}
ul.filelist li > svg {
    margin-right: 5px;
}
ul.filelist li button {
    font-size: 12px;
    margin-left: 5px;
    padding: 0 8px 2px;
    text-align: center;
}
ul.filelist li button:first-of-type {
    margin-left: 10px;
}
ul.filelist li button.delete {
    background: #930000;
    border-color: #930000;
    font-size: 16px;
    line-height: 12px;
    padding: 2px 8px;
}

hr {
    border: 1px solid #aaa;
    clear: both;
    margin: 0 40px 30px;
    width: calc(100% - 80px);
}

.act_link {
    cursor: pointer;
    /* text-decoration: underline; */
}
.act_link:hover {
    color: #157347;
    font-weight: bold;
}

.page_title {
    background: #44B2EB;
    margin-bottom: 20px;
    padding: 20px;
}
.page_title.help {
    background: #d90000;
}
.page_title h1 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
}
.waste_btn {
    background: #44B2EB ;
    border: 0;
    /* border-radius: 30px; */
    box-shadow: 0;
    font-size: 16px;
    font-weight: 700;
    margin: 0 auto;
    padding: 15px 20px;
    text-transform: uppercase;
    width: 100%;
}
.waste_btn.help {
    background: #d90000;
}
.waste_btn.mini {
    padding: 3px 10px !important;
}

.action_bar {
    padding: 0 8px;
    text-align: left;
}
.action_bar.top {
    margin: 30px 0 30px;
}
.action_bar.bottom {
    margin-top: 30px;
    padding: 0 40px;
}
.action_bar button {
    border: 0;
    border-radius: 0;
    overflow: hidden;
    padding: 5px 20px 5px 50px;
    position: relative;
}
.action_bar button svg {
	background: rgba(255,255,255,0.4);
	color: #fff;
	font-size: 24px;
	height: 34px;
	left: 0;
    padding: 5px;
	position: absolute;
	top: 0;
	width: 34px;
	z-index: 20;
}
.action_bar.bottom button {
    margin-right: 20px !important;
}
.action_bar.bottom button.back {
    background: #000;
}
.action_bar.bottom button.back:hover svg {
    background: #000 !important;
}
.action_bar button:hover {
    /* padding-left: 100%; */
}
.action_bar.top button:hover svg {
    width: 100%;
}
.action_bar.top button.btn-success.batch {
    background: #b740c9;
}
.action_bar.top button.btn-success:hover svg {
    background: #157347;
}
.action_bar.top button.btn-success.batch:hover svg {
    background: #b740c9;
}
.action_bar.top button.btn-primary:hover svg {
    background: #014A94;
}
.action_bar.bottom button:hover svg {
    background: #014A94;
    width: 100%;
}
.action_bar.one_row {
    background: #ddd;
    display: flex;
    margin: 0;
    padding: 20px 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.action_bar.one_row button {
    margin-right: 20px;
    width: auto;
}

div.alert {
    text-align: center;
}

.pageListWrapper {
    display: flex;
    justify-content: center;
    margin: 40px 0 0;
    text-align: center;
    width: 100%;
}
.pagesList {
    margin: 0;
    padding: 0;
}
.pagesList li {
    background: #aaa;
    /* border-radius: 5px; */
    color: #fff;
    cursor: pointer;
    height: 40px;
    width: 44px;
    display: block;
    float: left;
    font-size: 16px;
    margin: 0 5px 0 0;
    text-align: center;
    line-height: 40px;
    list-style-type: none;
    transition: all 300ms;
}
/* 
.pagesList li:first-of-type {
    margin-right: 15px;
}
.pagesList li:last-of-type {
    margin-left: 15px;
}
*/
.pagesList li.active {
    background: #198754;
    border-radius: 10px;
    width: 80px;
    font-size: 18px;
    font-weight: 600;
}
.pagesList li:hover {
    background: #014A94;
}

#stock_info {
    background: #f2d94f;
    font-weight: 600;
    margin: 30px 12px 10px;
    max-width: 400px;
    padding: 0;
}
#stock_info span {
    display: inline-block;
    padding: 20px 20px;
}
#stock_info span:last-of-type {
    background: rgba(255,255,255,0.5);
    float: right;
}

.activity_info {
    background: #eee;
    margin: 0 30px 60px;
    padding: 0 20px 0 0;
}
.activity_info .title {
	align-items: center;
	background: rgba(0,0,0,0.1);
	display: flex;
	flex-direction: column;
	font-size: 20px;
	font-weight: 600;
	justify-content: space-around;
	text-align: center;
	text-transform: uppercase;
}
.activity_info .title h2 {
    margin: 0;
}
.activity_info ul {
    margin: 20px 0;
}
.activity_info ul li {
    list-style-type: none;
}

.marker_label {
    background: #fff;
    padding: 3px;
    border-radius: 3px;
}
.marker_label_special {
    background: green;
    color: #fff !important;
    padding: 5px;
    border-radius: 5px;
}

.colDetail {
    background: #dfdfdf;
    padding: 10px;
    margin-right: 20px;
}
.colDetail h3 {
    background: #fff;
    padding: 10px;
}
.colDetail hr {
	border: 1px solid #777;
	clear: both;
	margin: 5px 0 0 -15px;
	width: 100%;
}

/* HEADER */

#header {
    background: #fff;
    border-bottom: 1px solid #eee;
    box-shadow: 0 1px 3px #ccc;
    display: block;
    height: 70px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto !important;
    max-width: 1320px;
    overflow: hidden;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    z-index: 100;
}
#header .row .col:first-of-type {
    vertical-align: middle;
}
#header .row .col:last-of-type {
    font-size: 26px;
    text-align: right;
}
#headicons_wrapper .headicon {
    cursor: pointer;
    float: right;
    margin: 0 10px;
    position: relative;
}
#headicons_wrapper .headicon span {
    background: #d90000;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: -5px;
    text-align: center;
    top: 0;
    width: 20px;
    z-index: 10;
}
#logo {
    cursor: pointer;
    height: 100px;
}
#header #logo {
    height: 50px;
}

/* LOGIN */

#login_form_wrapper {
    margin-top: 10%;
    text-align: center;
}
@media all and (max-width: 480px) {
    #login_form_wrapper { margin-top: 50%; }
}
#login_form_wrapper img {
    margin-bottom: 20px;
    /* max-width: 200px; */
}

.custom_form_wrapper {
    padding: 0 40px;
}
.custom_form {
    margin-bottom: 40px;
}
.custom_form label, .custom_form input, .custom_form select, .custom_form textarea {
    margin: 8px 0;
}
.custom_form input[type=text],
.custom_form input[type=password],
.custom_form input[type=file],
.custom_form input[type=date],
.custom_form input[type=number],
.custom_form input[type=email],
.custom_form input[type=time],
.custom_form select,
.custom_form textarea {
    background-color: #efefef;
    border: 0;
    /* border-radius: 30px; */
    color: #555;
    line-height: 1.2;
    padding: 10px 20px;
    width: 100%;
}
.custom_form textarea {
    min-height: 120px;
}
.custom_form textarea.long {
    min-height: 250px;
}
.custom_form button[type="submit"] {
	display: block;
	width: 240px;
	margin: 30px auto 0;
	border-radius: 0;
}

#tags_wrapper {
    background: #efefef;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    margin: 10px 0 20px;
    padding: 10px 10px 5px;
}
#tags_wrapper li {
    background: #fff;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    list-style-type: none;
    margin: 0 10px 10px 0;
    padding: 3px 10px;
    text-indent: 0;
}
#tags_wrapper li.chosen {
    background: green;
    color: #fff;
}

.MuiFormGroup-root {
	display: block !important;
    margin: 0 0 20px !important;
}
.MuiFormGroup-root * {
    margin: 0 !important;
}
form > label {
    text-align: center;
    width: 100%;
}
.MuiFormGroup-root label {
	width: 50% !important;
	display: inline-block !important;
}

/* MENU */

#menu {
    background: #eee;
    display: block;
    height: 100vh;
    max-width: 550px;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: all 200ms;
    width: 100%;
    z-index: 100;
}
#menu.active {
    transform: translateX(0%);
}
#menu .sx {
    background: #fff;
    font-size: 26px;
    text-align: center;
    height: 100vh;
    padding: 18px 0 0 10px !important;
    width: 80px !important;
}
#menu .sx svg {
    cursor: pointer;
}
#menu .dx {
    height: 100vh;
    overflow: scroll;
    padding: 30px 30px 30px 20px;
    width: calc(100% - 80px) !important;
}
#menu .dx .block {
    background: #ddd;
    border-radius: 10px;
    margin: 20px 0;
    padding: 10px 12px;
}
#menu .dx .block .col {
    padding: 0;
}
#menu .dx .block .col select {
    border: 1px solid #aaa;
    border-left: 4px solid #014A94;
    border-right: 4px solid #014A94;
    border-radius: 10px;
    padding: 3px 5px 5px;
    width: 90%;
}
h2, #menu h2 {
    color: #014A94;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.content h2 {
    margin: 0 20px 20px;
}
.content .boxed {
    margin: 0 12px;
}
h3, #menu h3 {
    color: #111;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    vertical-align: middle;
}
h3.centered {
    text-align: center;
}
h2 button, h3 button {
    float: right;
    vertical-align: middle;
}
#menu nav {
    margin-top: 20px;
}
#menu ul {
    padding-left: 0;
}
#menu li {
    list-style-type: none;
    padding: 0;
    text-indent: none;
}
#menu a {
    border-bottom: 1px solid #ddd;
    color: #111;
    display: block;
    font-weight: 600;
    padding: 15px 10px;
    text-decoration: none;
}
#menu a:hover {
    background: #ddd;
}
#menu ul > li > ul {
    background: #ddd;
    display: none;
    padding: 0;
}
#menu ul > li > ul > li a {
    border-bottom: 1px solid #fff;
    padding: 15px 30px;
}
#menu ul > li > ul > li:last-of-type a {
    border-bottom: 0;
}
#menu ul > li > ul > li a:hover {
    background: #ccc;
}
#menu ul > li > ul.visible {
    display: block;
}
#menu a.logout {
    color: #930000;
}
#menu nav a svg {
	display: inline-block;
	font-size: 22px;
	margin-right: 8px;
}
#info_connected {
    align-items: center;
    background: #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    line-height: 1;
    padding: 10px;
}
#info_connected > div {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 5px 0;
    text-align: left;
    width: 100%;
}
#info_connected div svg {
    font-size: 32px;
    margin: 0 20px 0 0;
}
#info_connected div div {
    display: block;
    height: 100%;
    line-height: 16px;
}
#info_connected div span {
    display: block;
}
#info_connected div span.bottom {
    margin-top: 5px;
}
#info_connected div span.top {
    margin-bottom: 3px;
}

/* HOMEPAGE */

#search_form {
    margin: 0;
}
#search_form * {
    margin: 0;
}
#searchbox {
    background: #014A94;
    margin-bottom: 20px;
    min-height: 100px;
    padding: 40px;
    text-align: center;
}
#searchbox p {
    color: #fff;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
}
#searchbox .keyword_wrapper {
    padding: 0;
    position: relative;
    width: 100%;
    margin: 0 auto;
}
#searchbox .keyword_wrapper input {
    border-radius: 30px;
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;
}
#searchbox .keyword_wrapper svg {
    position: absolute;
    font-size: 22px;
    right: 15px;
    top: 12px;
}

/* ITEMS LIST */

.items_list {
    padding: 0;
}
.items_list li {
    background: #eee;
    border: 0;
    border-radius: 10px;
    /* box-shadow: 1px 1px 15px #ccc; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto 20px;
    min-height: 65px;
    overflow: hidden;
    padding: 10px 15px;
    position: relative;
    width: 90%;
}
.items_list li > .btn_wrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.items_list li > .btn_wrapper > button {
    border-radius: 20%;
    flex-grow: 1;
    margin: 0 2px;
    padding: 10px;
}
.items_list li button svg {
    font-size: 22px;
}
.items_list li .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.1;
    max-width: 60%;
}
.items_list li .detail {
    color: #555;
    font-size: 14px;
    font-weight: 600;
}
.items_list li .detail svg {
    display: inline-block;
    font-size: 16px;
    margin-top: -5px;
    vertical-align: middle;
}
.items_list li .description {
    color: #555;
    font-size: 15px;
    font-weight: 200;
    line-height: 1.2;
    margin-top: 5px;
}
.items_list li .description b {
    color: #000;
    font-weight: 600;
}
.items_list li .goToDetail {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 5px;
    padding-right: 10px;
    text-align: right;
}
.items_list li svg.title_icon {
    float: left;
    font-size: 26px;
    margin: 0 0 0 -8px;
    padding-top: 5px;
    vertical-align: middle;
}
.items_list svg.arrow {
    font-size: 32px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}
.items_list li::after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    right: 40px;
    top: 15px;
    width: 15px;
    z-index: 10;
}
.items_list li.expired::after,
.items_list li.scheduled::after,
.items_list li.published::after {
    left: 12px;
    top: 15px;
}
.items_list li.expired .detail,
.items_list li.scheduled .detail,
.items_list li.published .detail {
    margin-bottom: 5px;
    padding-left: 20px;
}
.items_list li.open::after,
.items_list li.expired::after {
    background: #d90000;
}
.items_list li.unread::after,
.items_list li.scheduled::after {
    background: #edc31e;
}
.items_list li.closed::after,
.items_list li.published::after {
    background: #53c43c;
}
.items_list li div.icon {
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 10;
}
#flash_list li div.icon {
    right: 40px;
}
#areas_list li div.icon {
    /* border: 1px solid red; */
    font-size: 20px;
    line-height: 1;
    right: 115px;
    top: 50%;
    transform: translateY(-50%);
}
.items_list li .answer h4 {
    margin: 0;
}
.items_list li .answer h4 + .title {
    margin-bottom: 10px;
}

.items_list.with_img li {
    padding-left: 80px;
}
.items_list.with_img li .title {
    max-width: 85%;
}
.items_list.with_img li img {
    border-radius: 10px;
    max-height: calc(100% - 20px);
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
}

#help_row {
    margin-top: 40px;
    padding: 0 40px;
}
h4 {
    color: #014A94;
    font-weight: 600;
    letter-spacing: -1px;
    text-align: center;
    text-transform: uppercase;
}

/* CER DETAIL */

#cer_title {
    padding: 40px 20px 80px;
}
#cer_detail {
    margin: 0 40px;
}
#cer_detail img {
    border-radius: 30px;
    margin: -60px 0 20px;
    width: 100%;
}
#locations_list {
    margin: 0;
    padding: 0;
}
#locations_list li {
    background: #f6f6f6;
    border: 1px solid #DDD;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    float: left;
    justify-content: space-between;
    list-style-type: none;
    margin: 0 2.5%;
    min-height: 100px;
    padding: 20px;
    text-indent: 0;
    width: 47.5%;
}
#locations_list li p {
    margin: 0;
}
#locations_list li .btn {
    margin-top: 10px;
}
#locations_list li:nth-of-type(2n+1) {
    margin-left: 0 !important;
}
#locations_list li:nth-of-type(2n) {
    margin-right: 0 !important;
}

/* ERROR DETAIL */

#error_detail {
    text-align: center;
}
#error_detail svg {
    font-size: 40px;
    margin-bottom: 20px;
}
p.evidence {
    color: #111;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 40px;
}
.check_mini_img {
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    float: left;
    height: 40px;
    margin-right: 10px;
    width: 40px;
}
.accordion-button {
    background: #eee !important;
    border: 0 !important;
    border-radius: 20px !important;
    display: block;
    margin-bottom: 15px !important;
    padding: 10px 15px !important;
}
h2.accordion-header p {
	display: block;
	float: left;
    margin-bottom: 0 !important;
	width: calc(100% - 90px);
}
.accordion-button::after {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%) !important;
}
.accordion-button:not(.collapsed)::after {
    transform: translateY(-50%) rotate(-180deg) !important;
}
.accordion-item {
    border: 0 !important;
}
.accordion-collapse.show .accordion-body {
	/*
    background: #f6f6f6;
	border: 0;
	border-radius: 20px;
	margin-bottom: 10px;
	text-align: justify;
    */
	padding: 70px 20px 20px;
}

/* RESPONSIVE */

@media all and (min-width: 1920px) {
    #header {
        max-width: 100%;
        padding: 10px 45px;
    }
    #headicons_wrapper .headicon:first-of-type {
        display: none;
    }
    #menu {
        left: 0;
        right: auto;
        transform: translateX(0%) !important;
        top: 70px;
        max-width: 20% !important;
        width: 20% !important;
    }
    #menu .sx {
        display: none;
    }
    #menu .dx {
        padding: 30px 60px !important;
        width: 100% !important;
    }
    .content:not(.noMargin) {
        margin-left: 20% !important;
        max-width: 80% !important;
    }
}
@media all and (max-width: 1920px) {
    #menu .dx {
        padding: 30px !important;
    }
    table td.actions {
        max-width: 85px;
    }
}
@media all and (max-width: 1600px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 100% !important;
    }
    td.tdcol span {
        display: block;
        width: 100%;
    }
}
@media all and (min-width: 768px) and (max-width: 1600px) {
    #menu {
        max-width: 40% !important;
    }
}
@media all and (max-width: 480px) {
    .action_bar {
        flex-wrap: wrap;
    }
    .action_bar .col {
        flex: none;
        margin-bottom: 10px;
        width: 100%;
    }
    .items_list li.expired .detail, .items_list li.scheduled .detail, .items_list li.published .detail {
        max-width: 140px;
    }
    #menu a {
        padding: 12px 0;
    }
    #menu .sx {
        width: 70px !important;
    }
    #menu .dx {
        width: calc(100% - 70px) !important;
    }
}